import axios from "axios";

export default {
  get: (page, pagesize, place, status) => {
    const params = {
      page: page,
      pagesize: pagesize,
      place: place,
      status: status,
    };
    return axios.get("/a1/banner", { params: params });
  },
  add: (params) => {
    return axios.post("/a1/banner", params);
  },
  edit: (params) => {
    return axios.put("/a1/banner", params);
  },
  del: (id) => {
    return axios.delete("/a1/banner", { params: { id: id } });
  },
};

import common from "@/common/mixins/common.js";
import banner from "@/api/admin/banner";

export default {
  name: "banner",
  mixins: [common],
  data() {
    return {
      addBannerWrap: false,
      add: true,
      editid: 1,
      bannerForm: {
        title: "",
        place: "",
        imageUrl: "",
        jumpUrl: "",
        time: "",
        status: 0,
        order: 0,
      },
      placeOption: [{ label: "首页", value: 0 },{label:"热销产品",value:99}],
      statusOption: [
        { label: "展示", value: 1 },
        { label: "隐藏", value: 0 },
      ],
      pageInfo: {
        page: 1,
        pagesize: 10,
        total: 0,
      },
      bannerData: [],
      disabled: false,
      showImgInfo: false,
      dialogImageUrl: "",
      filelist: [],
      imageUrl: "",
      uploadHeaders: {
        Authorization: JSON.parse(sessionStorage.getItem("token")),
      },
    };
  },
  watch: {
    addBannerWrap(val) {
      if (!val)
        this.bannerForm={
          title: "",
          place: "",
          imageUrl: "",
          jumpUrl: "",
          time: "",
          status: 0,
          order: 0,
        };
    },
  },
  created() {
    this.getBanner();
    this.getPlace();
  },
  methods: {
    //改变条数
    handleRenewRenewSizeChange(val) {
      this.pageInfo.pagesize=val;
      this.getBanner();
    }, //翻页
    handleChangeCurrentPage(val) {
      this.pageInfo.page=val;
      this.getBanner();
    },
    handleAddBanner() {
      this.add=true;
      this.addBannerWrap=true;
    },
    submitBanner() {
      const params=this.formatParams();
      banner
        .add(params)
        .then((res) => {
          res.data;
          this.$message.success("创建成功");
          this.getBanner();
          this.addBannerWrap=false;
        })
        .catch(() => {
          this.$message.error("创建失败");
        });
    },
    editBanner() {
      let params=this.formatParams();
      params.id=this.editid;
      banner.edit(params).then(() => {
        this.$message.success("修改成功");
        this.getBanner();
        this.addBannerWrap=false;
      });
    },
    delBanner(row) {
      banner.del(row.id).then(() => {
        this.$message.success("删除成功");
        this.getBanner();
      });
    },
    getBanner() {
      banner.get(this.pageInfo.page, this.pageInfo.pagesize, -1, -1).then((res) => {
        this.bannerData=res.data.data.data;
        this.pageInfo.total=res.data.data.total;
      });
    },
    handleEdit(type, r) {
      switch (type) {
        case "edit":
          const row=JSON.parse(JSON.stringify(r));
          this.add=false;
          this.addBannerWrap=true;
          this.editid=row.id;
          this.bannerForm.title=row.title;
          this.bannerForm.jumpUrl=row.jumpUrl;
          this.bannerForm.imageUrl=row.imageUrl;
          this.bannerForm.place=row.place;
          this.bannerForm.status=row.status;
          this.bannerForm.order=row.order;
          const start=row.startTime > 0 ? new Date(row.startTime * 1000) : "";
          const end=row.startTime > 0 ? new Date(row.endTime * 1000) : "";
          this.bannerForm.time=[start, end];
          break;
        case "del":
          this.$confirm("此操作将永久删除banner, 是否继续?", "删除banner", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            })
            .then(() => {
              this.delBanner(r);
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消删除",
              });
            });
          break;
      }
    },
    handleImgSuccess(res, file) {
      if (res.error === 0) this.bannerForm.imageUrl=JSON.parse(JSON.stringify(res.data));
      this.bannerForm.imageUrl=file.response.data;
    },
    formatParams() {
      return {
        place: this.bannerForm.place,
        title: this.bannerForm.title,
        image: this.bannerForm.imageUrl,
        jumpUrl: this.bannerForm.jumpUrl,
        startTime: this.bannerForm.time != null ? new Date(this.bannerForm.time[0]).getTime() / 1000 : 0,
        endTime: this.bannerForm.time != null ? new Date(this.bannerForm.time[1]).getTime() / 1000 : 0,
        status: this.bannerForm.status,
        order: parseInt(this.bannerForm.order),
      };
    },
    getPlace() {
      this.$axios.get("/a1/series").then((res) => {

        res.data.data.forEach((item) => {
          this.placeOption.push({ label: item.name, value: item.id });
        });

      });
    },
  },
};

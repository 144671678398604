<template>
  <div class="banner">
    <div class="banner-header">
      <el-button @click="handleAddBanner">添加</el-button>
      <el-dialog title="重新上新" :visible.sync="addBannerWrap" width="35%">
        <el-form ref="form" label-width="100px">
          <el-form-item label="标题">
            <el-input v-model="bannerForm.title"></el-input>
          </el-form-item>
          <el-form-item label="展示位置">
            <el-select v-model="bannerForm.place" placeholder="请选择">
              <el-option v-for="item in placeOption" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="跳转地址">
            <el-input v-model="bannerForm.jumpUrl"></el-input>
          </el-form-item>
          <el-form-item label="状态">
            <el-select v-model="bannerForm.status" placeholder="请选择">
              <el-option v-for="item in statusOption" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="排序">
            <el-input type="number" v-model="bannerForm.order"></el-input>
          </el-form-item>
          <el-form-item label="展示日期">
            <el-date-picker
              v-model="bannerForm.time"
              type="datetimerange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-upload
              class="avatar-uploader"
              :action="this.$root.DownUrl + '/a1/upload/file'"
              :headers="uploadHeaders"
              :show-file-list="false"
              :on-success="handleImgSuccess"
              :auto-upload="true"
            >
              <img v-if="bannerForm.imageUrl" :src="bannerForm.imageUrl" class="avatar" alt="" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
          <el-dialog :visible.sync="showImgInfo" :modal="false">
            <img width="100%" :src="dialogImageUrl" alt="" />
          </el-dialog>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="addBannerWrap = false">取 消</el-button>
          <el-button type="primary" @click="submitBanner" v-if="add">提 交</el-button>
          <el-button type="primary" @click="editBanner" v-if="!add">编 辑</el-button>
        </span>
      </el-dialog>
    </div>
    <div class="banner-content">
      <div class="banner-content-list">
        <el-table :data="bannerData">
          <el-table-column prop="id" label="id" width="50" align="center"></el-table-column>
          <el-table-column prop="place" label="展示位置">
            <template v-slot="{ row }">
              <span v-for="item in placeOption">{{ item.value === row.place ? item.label :""}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="title" label="标题"></el-table-column>
          <el-table-column prop="imageUrl" label="图片">
            <template v-slot="{ row }">
              <el-image class="banner-viewer" :src="row.imageUrl" :preview-src-list="[row.imageUrl]"></el-image>
            </template>
          </el-table-column>
          <el-table-column prop="jumpUrl" label="跳转地址"></el-table-column>
          <el-table-column prop="status" label="状态">
            <template v-slot="{ row }">
              <span> {{ row.status === 1 ? "展示" : "不展示" }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="order" label="排序"></el-table-column>
          <el-table-column label="开始时间">
            <template v-slot="{ row }">
              {{ row.startTime | formatDateStr("yyyy-MM-dd") }}
            </template>
          </el-table-column>
          <el-table-column label="结束时间">
            <template v-slot="{ row }">
              {{ row.endTime | formatDateStr("yyyy-MM-dd") }}
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template v-slot="{ row }">
              <el-button type="primary" plain @click="handleEdit('edit', row)">编辑</el-button>
              <el-button type="primary" plain @click="handleEdit('del', row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div ref="banner-footer" class="banner-footer" v-if="bannerData.length > 0">
        <el-pagination
          :key="1"
          :current-page="pageInfo.page"
          :page-size="pageInfo.pagesize"
          :page-sizes="[10, 20, 50, 100, 200]"
          layout="total,sizes, prev, pager, next, jumper"
          :total="pageInfo.total"
          @size-change="handleRenewRenewSizeChange"
          @current-change="handleChangeCurrentPage"
          background
        />
      </div>
    </div>
  </div>
</template>

<script>
import banner from "./js/index";

export default banner;
</script>

<style scoped lang="scss">
@import "css/index";
</style>

<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
</style>
